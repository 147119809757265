import Howitworks from "../Howitworks/Howitworks";
import Howitworksmobile from "../Howitworks/Howitworksmobile";
import MediaQuery from "react-responsive";
import data from "../../Data/Data";
export default function RadiologyHowitworks() {
  return (
    <>
      {/* How It Works  */}
      <MediaQuery maxWidth={991}>
        <Howitworksmobile
          stepOneText={data.Radiologist["How It Works"].step1title}
          stepOnedescription={data.Radiologist["How It Works"].step1sub}
          stepTwoText={data.Radiologist["How It Works"].step2title}
          stepTwodescription={data.Radiologist["How It Works"].step2sub}
          stepThreeText={data.Radiologist["How It Works"].step3title}
          stepThreedescription={data.Radiologist["How It Works"].step3sub}
        />
      </MediaQuery>
      <MediaQuery minWidth={992}>
        <Howitworks
          stepOneText={data.Radiologist["How It Works"].step1title}
          stepOnedescription={data.Radiologist["How It Works"].step1sub}
          stepTwoText={data.Radiologist["How It Works"].step2title}
          stepTwodescription={data.Radiologist["How It Works"].step2sub}
          stepThreedescription={data.Radiologist["How It Works"].step3sub}
          stepThreeText={data.Radiologist["How It Works"].step3title}
        />
      </MediaQuery>
    </>
  );
}
