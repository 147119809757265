import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../../Components/Button/Button";
import { THEME } from "../../const";
const apiKey =
  "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";

const Newsletter = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!email) {
      setError(true);
      return;
    }

    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        "api-key": apiKey,
      },
      body: JSON.stringify({
        // attributes: {Email: email},
        email: email,
        listIds: [5],
        updateEnabled: false,
      }),
    };

    fetch("https://api.brevo.com/v3/contacts", options)
      .then((r) => {
        r.status === 201 || r.status === 204
          ? setSuccess(true)
          : setError(true);
        return r;
      })
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
      })
      .catch((err) => {
        setSuccess(false);
        setError(true);
        console.error(err);
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {success && (
        <Form.Label>{`Thanks for subscribing! (${email})`}</Form.Label>
      )}
      {error && (
        <Form.Label>
          There was an error signing you up. Is your email valid?
        </Form.Label>
      )}

      {!success && (
        <Row>
          <Col md={7} className="mt-2">
            <Form.Group controlId="formEmail">
              <Form.Control
                style={{
                  fontFamily: "IBMPlexSans-Regular",
                  fontSize: "16px",
                  borderTopLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                  height: "50px",
                  paddingLeft: "2em",
                  paddingRight: "2em"
                }}
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={5} className="mt-2">
            <Button
              type="submit"
              primarybtn
              style={{
                backgroundColor: `${THEME.PRIMARY}`,
                color: `${THEME.WHITE}`,
                borderTopLeftRadius: "15px",
                borderBottomRightRadius: "15px",
                fontFamily: "IBMPlexSans-Regular",
                fontSize: "16px",
                height: "50px",
                width: "100%",
                border: "0px",
              }}
              text="Join our newsletter"
            />
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default Newsletter;
