import { motion } from "framer-motion";
import { Col, Row } from "react-bootstrap";
import MediaQuery from "react-responsive";
import Text from "../../Components/Text/Text";
import { THEME } from "../../const";
import data from "../../Data/Data";
import bgimg from "./Img/bgimg.jpg";
import mobbg from "./Img/mobbg.png";
import "./PlatformHero.css";
import { PopupButton } from "@typeform/embed-react";

export default function PlatformHero() {
  return (
    <>
      <MediaQuery minWidth={701}>
        <div style={{ backgroundColor: "#FFF" }}>
          <div
            className="respborderBottomRightRadius"
            style={{
              paddingTop: "40vh",
              backgroundImage: `url(${bgimg}) `,
              backgroundPosition: "50% 40%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              borderBottomRightRadius: "250px",
              // borderBottomLeftRadius: "250px",
              overflow: "hidden",
            }}
          >
            <Row>
              <Col lg={8} md={8}>
                <div
                  style={{
                    backgroundColor: "rgb(131 98 170 / 92%)",
                    borderTopRightRadius: "250px",
                    padding: "0px",
                    paddingLeft: "80px",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "100px",
                      paddingBottom: "100px",
                      paddingRight: "100px",
                    }}
                  >
                    <motion.div
                      initial={{ x: "-5vw" }}
                      animate={{ x: 0 }}
                      transition={{
                        type: "spring",
                        duration: 3,
                      }}
                    >
                      <Text
                        className="whitespace"
                        h2
                        bold
                        color={THEME.WHITE}
                        text={data.Platform.Hero.title}
                      />
                    </motion.div>
                    <motion.div
                      initial={{ x: "5vw" }}
                      animate={{ x: 0 }}
                      transition={{
                        type: "spring",
                        duration: 3,
                      }}
                    >
                      <Text
                        className="pt-3 whitespace"
                        regular
                        color={THEME.WHITE}
                        text={data.Platform.Hero.sub}
                      />
                      <Row style={{ display: "flex", paddingTop: "3rem" }}>
                        <Col></Col>
                        <Col style={{ justifyContent: "flex-end" }}>
                          <PopupButton
                            id="wqyOGCkj"
                            // style={{ fontSize: 20 }}
                            className="my-button"
                            style={{
                              backgroundColor: `${THEME.PRIMARY}`,
                              borderTopLeftRadius: "25px",
                              borderBottomRightRadius: "25px",
                              fontFamily: "IBMPlexSans-Regular",
                              fontSize: "20px",
                              height: "60px",
                              width: "300px",
                              border: "1px solid black",
                              color: `white`,
                              transform: "translate(0px, -7px)",
                            }}
                          >
                            Request Demo
                          </PopupButton>
                        </Col>
                      </Row>
                    </motion.div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </MediaQuery>

      {/* mobile banner start */}
      <MediaQuery maxWidth={700}>
        <div style={{ backgroundColor: "#F9F9F9" }}>
          <div
            style={{
              paddingTop: "35vh",
              paddingBottom: "25vh",
              backgroundImage: `url(${mobbg}) `,
              backgroundPosition: "TOP",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              borderBottomRightRadius: "35vh",
            }}
          >
            <Row>
              <Col lg={8}>
                <div className="text-center">
                  <div style={{ paddingLeft: "24px", paddingRight: "24px" }}>
                    <Text
                      className="p-3"
                      fontSize="40px"
                      bold
                      color={THEME.BLACK}
                      text={data.Platform.Hero.title}
                    />
                    <br />
                    <Text
                      className="pt-3"
                      fontSize="24px"
                      color={THEME.BLACK}
                      text={data.Platform.Hero.sub}
                    />
                    <Row style={{ display: "flex", paddingTop: "3rem" }}>
                      <Col style={{ justifyContent: "center" }}>
                        <PopupButton
                          id="wqyOGCkj"
                          // style={{ fontSize: 20 }}
                          className="my-button"
                          style={{
                            backgroundColor: `${THEME.PRIMARY}`,
                            borderTopLeftRadius: "25px",
                            borderBottomRightRadius: "25px",
                            fontFamily: "IBMPlexSans-Regular",
                            fontSize: "20px",
                            height: "60px",
                            width: "300px",
                            border: "1px solid black",
                            color: `white`,
                            transform: "translate(0px, -7px)",
                          }}
                        >
                          Request Demo
                        </PopupButton>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </MediaQuery>

      {/* mobile banner start */}
    </>
  );
}
