import data from "../../Data/Data";
export const copyfaq1 = [
  {
    title: data.Radiologist.Faq.question1Title,
    description: data.Radiologist.Faq.question1sub,
  },
  {
    title: data.Radiologist.Faq.question2Title,
    description: data.Radiologist.Faq.question2sub,
  },
  {
    title: data.Radiologist.Faq.question3Title,
    description: data.Radiologist.Faq.question3sub,
  },
  {
    title: data.Radiologist.Faq.question4Title,
    description: data.Radiologist.Faq.question4sub,
  },
  {
    title: data.Radiologist.Faq.question5Title,
    description: data.Radiologist.Faq.question5sub,
  },
  {
    title: data.Radiologist.Faq.question6Title,
    description: data.Radiologist.Faq.question6sub,
  },
];
export const copyfaq2 = [
  {
    title: data.Patients.Faq.question1Title,
    description: data.Patients.Faq.question1sub,
  },
  {
    title: data.Patients.Faq.question2Title,
    description: data.Patients.Faq.question2sub,
  },
  {
    title: data.Patients.Faq.question3Title,
    description: data.Patients.Faq.question3sub,
  },
  {
    title: data.Patients.Faq.question4Title,
    description: data.Patients.Faq.question4sub,
  },
  {
    title: data.Patients.Faq.question5Title,
    description: data.Patients.Faq.question5sub,
  },
  {
    title: data.Patients.Faq.question6Title,
    description: data.Patients.Faq.question6sub,
    link: data.Patients.Faq.question6sublink,
  },
];
export const copyfaq3 = [
  {
    title: data.Physicians.Faq.question1Title,
    description: data.Physicians.Faq.question1sub,
  },
  {
    title: data.Physicians.Faq.question2Title,
    description: data.Physicians.Faq.question2sub,
  },
  {
    title: data.Physicians.Faq.question3Title,
    description: data.Physicians.Faq.question3sub,
  },
  {
    title: data.Physicians.Faq.question4Title,
    description: data.Physicians.Faq.question4sub,
  },
  {
    title: data.Physicians.Faq.question5Title,
    description: data.Physicians.Faq.question5sub,
  },
  {
    title: data.Physicians.Faq.question6Title,
    description: data.Physicians.Faq.question6sub,
  },
];
export const copyfaq4 = [
  {
    title: data.Clinic.Faq.question1Title,
    description: data.Clinic.Faq.question1sub,
  },
  {
    title: data.Clinic.Faq.question2Title,
    description: data.Clinic.Faq.question2sub,
  },
  {
    title: data.Clinic.Faq.question3Title,
    description: data.Clinic.Faq.question3sub,
  },
  {
    title: data.Clinic.Faq.question4Title,
    description: data.Clinic.Faq.question4sub,
  },
  // {
  //   title: data.Clinic.Faq.question5Title,
  //   description: data.Clinic.Faq.question5sub,
  // },
  {
    title: data.Clinic.Faq.question6Title,
    description: data.Clinic.Faq.question6sub,
  },
];