import MediaQuery from "react-responsive";
import Faq from "../../Sections/Faq/Faq";
import Features from "../../Sections/Features/Features";
import Featuresmobile from "../../Sections/Features/Featuresmobile";
// import PricingPlan from "../../Sections/PricingPlan/PricingPlan";
// import PricingPlanmobile from "../../Sections/PricingPlan/PricingPlanmobile";
import ClinicHowitworks from "../../Sections/ClinicHowitworks/ClinicHowitworks";
import Clinicischangingmobile from "../../Sections/Clinicischanging/Clinicischangingmobile";
import Clinicischanging from "../../Sections/Clinicischanging/Clinicischanging";
import InsightsClinics from "../../Sections/InsightsClinics/InsightsClinics";
import VirtualRadiology from "../../Sections/VirtualRadiology/VirtualRadiology";
import { copyRadiologists } from "../../Sections/Features/copy";
import { copyfaq4 } from "../../Sections/Faq/copy";
import ClinicHero from "../../Sections/ClinicHero/ClinicHero";
// import Contact from "../../Sections/Contact/Contact";


export default function Clinics() {
  return (
    <>
      <ClinicHero />
      
      {/* Radiology changing */}
      <MediaQuery maxWidth={766}>
        <Clinicischangingmobile />
      </MediaQuery>
      <MediaQuery minWidth={767}>
        <Clinicischanging />
      </MediaQuery>

      <VirtualRadiology />

      <ClinicHowitworks />

      <MediaQuery maxWidth={991}>
        <Featuresmobile copy={copyRadiologists} />
      </MediaQuery>
      <MediaQuery minWidth={992}>
        <Features copy={copyRadiologists} />
      </MediaQuery>

      <InsightsClinics />

      <Faq copy={copyfaq4} />
      
      {/* <Contact /> */}
    </>
  );
}
