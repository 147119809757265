import { Col, Container, Row } from "react-bootstrap";
import Text from "../../Components/Text/Text";
import pinkicon from "./Img/pinkicon.svg";
import yellowicon from "./Img/yellowicon.svg";
import purpleicon from "./Img/purpleicon.svg";
import quo from "./Img/quo.svg";
import Group from "./Img/Group 361 1.png";
import React from "react";
import data from "../../Data/Data";

export default function Patientsmobile() {
  const list = [
    {
      icon: pinkicon,
      title: data.Patients.Benefits.items.item1title,
      description: data.Patients.Benefits.items.item1sub,
    },
    {
      icon: purpleicon,
      title: data.Patients.Benefits.items.item2title,
      description: data.Patients.Benefits.items.item2sub,
    },
    {
      icon: yellowicon,
      title: data.Patients.Benefits.items.item3title,
      description: data.Patients.Benefits.items.item3sub,
    },
  ];
  return (
    <>
      <Container>
        <Text
          className="text-center pt-5 pb-5"
          fontSize="32px"
          bold
          text={<>Patients</>}
        />
        <Row className="pt-5">
          <Col lg={6}>
            <div className="text-center">
              <img src={Group} alt="" />
            </div>
            <div style={{ paddingBottom: "50px", marginTop: "-140px" }}>
              <div style={{ marginBottom: "-40px", paddingLeft: "20px" }}>
                <img src={quo} style={{ maxWidth: "25%" }} alt="" />
              </div>

              <Text
                className="text-center"
                fontSize="28px"
                bold
                text={data.Patients.Benefits.title}
              />
            </div>

            {list.map(({ title, icon, description }, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className="p-3 shadow"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                      marginTop: "50px",
                    }}
                  >
                    <Row className="align-items-center">
                      <Col lg={3}>
                        <div className="">
                          <img src={icon} alt="" />
                        </div>
                      </Col>
                      <Col lg={9}>
                        <Text fontSize="28px" bold text={title} />
                        <Text small text={description} />
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              );
            })}
          </Col>
        </Row>
      </Container>
    </>
  );
}
