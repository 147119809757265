import { PopupButton } from "@typeform/embed-react";
import { Col, Container, Row } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import Newsletter from "../Newsletter/Newsletter";
import Text from "../Text/Text";
import "./Footer.css";
// import logo from "./Img/brell_logo_05x.png";
import logo from "./Img/brell_logo_05x.jpg";
import Button from "../../Components/Button/Button";
import { LINK } from "../../const";

import HIPPA from "./Img/hippa.svg";
import { THEME } from "../../const";

export default function Footer() {
  return (
    <Container
      className=" pt-5 pb-5"
      style={{ borderTop: "2px solid black", marginTop: "150px" }}
    >
      <Row className="justify-content-center">
        <Row
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
          }}
        >
          <Col md={4}>
            <div className="resfooterLogo">
              <img src={logo} height="70px" alt="" />
            </div>
          </Col>
          <Col md={8}>
            <Newsletter />
          </Col>
        </Row>
        <MediaQuery minWidth={767}>
          <Row style={{ paddingTop: "2em" }}>
            <PopupButton
              id="wqyOGCkj"
              // style={{ fontSize: 20 }}
              className="my-button"
              style={{
                backgroundColor: `${THEME.PRIMARY}`,
                borderTopLeftRadius: "25px",
                borderBottomRightRadius: "25px",
                fontFamily: "IBMPlexSans-Regular",
                fontSize: "16px",
                height: "46px",
                width: "300px",
                border: "1px solid black",
                color: `white`,
                transform: "translate(0px, -7px)",
              }}
            >
              Request Demo
            </PopupButton>
          </Row>
          <Row style={{ paddingTop: "1em" }}>
            <Button
              secondary
              small
              width="300px"
              text="Access My Records"
              onClick={(e: { preventDefault: () => void }) => {
                e.preventDefault();
                window.location.href = `${LINK.clinics}`;
              }}
            />
          </Row>
        </MediaQuery>
        <Col xs={12}>
          <MediaQuery maxWidth={766}>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "2rem",
                marginTop: "1rem",
              }}
            > */}
            <Row style={{ paddingLeft: "1.4rem", paddingRight: "1.4rem", paddingTop: '3em' }}>
              <PopupButton
                id="wqyOGCkj"
                // style={{ fontSize: 20 }}
                className="my-button"
                style={{
                  backgroundColor: `${THEME.PRIMARY}`,
                  borderTopLeftRadius: "25px",
                  borderBottomRightRadius: "25px",
                  fontFamily: "IBMPlexSans-Regular",
                  fontSize: "16px",
                  height: "46px",
                  width: "100%",
                  border: "1px solid black",
                  color: `white`,
                  transform: "translate(0px, -7px)",
                }}
              >
                Request Demo
              </PopupButton>
            </Row>
            <Row style={{ paddingLeft: "1.4rem", paddingRight: "1.4rem", paddingTop: '1em' }}>
              <Button
                secondary
                // icon
                width="100%"
                small
                text="Access My Records"
                onClick={(e: { preventDefault: () => void }) => {
                  e.preventDefault();
                  window.location.href = `${LINK.clinics}`;
                }}
              />
            </Row>
          </MediaQuery>

          <Row className="mt-4" style={{ marginTop: "1rem" }}>
            {/* <> */}
            <Col xs={12} md={3}>
              <Text
                className="mrTop"
                fontSize="13px"
                bold
                text="Who we serve"
              />

              <Link
                to="/care-centers/"
                className="pt-2"
                style={{ color: "#333741" }}
              >
                <Text className="pt-2" fontSize="13px" text="Imaging Centers" />
              </Link>
              <Link
                to="/specialists/"
                className="pt-2"
                style={{ color: "#333741" }}
              >
                <Text className="pt-2" fontSize="13px" text="Specialists" />
              </Link>
              <Link to="/patients/" style={{ color: "#333741" }}>
                <Text
                  className="pt-2"
                  fontSize="13px"
                  text="Patients & Advocates"
                />
              </Link>
              {/* <Link to="/physicians/" style={{ color: "#333741" }}>
                  <Text
                    className="pt-2"
                    fontSize="13px"
                    text="Physicians"
                  />
                </Link> */}
            </Col>

            <Col xs={12} md={4}>
              <Text
                className="mrTop"
                fontSize="13px"
                bold
                text="Products & Services"
              />
              <Text
                className="pt-2"
                fontSize="13px"
                text="Brell | Record Management"
              />
              <Text
                className="pt-2"
                fontSize="13px"
                text="Brell | Virtual Results Experience"
              />
              <Text
                className="pt-2"
                fontSize="13px"
                text="Brell | Virtual Expert Opinion Platform"
              />
              <Link
                to="/consult/"
                className="pt-2"
                style={{ color: "#333741" }}
              >
                <Text
                  className="pt-2"
                  fontSize="13px"
                  text="Brell | Get Expert Opinion Now"
                />
              </Link>
              <Link
                to="/records/"
                className="pt-2"
                style={{ color: "#333741" }}
              >
                <Text
                  className="pt-2"
                  fontSize="13px"
                  text="Brell | Record Request"
                />
              </Link>
              {/* <Text
                className="pt-2"
                fontSize="13px"
                text="Brell | Demand Network"
              /> */}
            </Col>

            {/* <Col xs={12} md={3}>
              <Text className="mrTop" fontSize="13px" bold text="Services" />
              <Link
                to="/consult/"
                className="pt-2"
                style={{ color: "#333741" }}
              >
                <Text
                  className="pt-2"
                  fontSize="13px"
                  text="Brell | Get Expert Opinion Now"
                />
              </Link>
              <Link
                to="/records/"
                className="pt-2"
                style={{ color: "#333741" }}
              >
                <Text
                  className="pt-2"
                  fontSize="13px"
                  text="Brell | Record Request"
                />
              </Link>
            </Col> */}

            <Col xs={12} md={4}>
              <Text className="mrTop" fontSize="13px" bold text="Brell" />
              <Link to="/login/" className="pt-2" style={{ color: "#333741" }}>
                <Text className="pt-2" fontSize="13px" text="Login" />
              </Link>
              <Link to="/" className="pt-2" style={{ color: "#333741" }}>
                <Text className="pt-2" fontSize="13px" text="The Platform" />
              </Link>
              <Link to="/terms/" className="pt-2" style={{ color: "#333741" }}>
                <Text
                  className="pt-2"
                  fontSize="13px"
                  text="Terms of Service"
                />
              </Link>
              <Link
                to="/privacy/"
                className="pt-2"
                style={{ color: "#333741" }}
              >
                <Text className="pt-2" fontSize="13px" text="Privacy" />
              </Link>
              {/* <Link
                  to="/login/"
                  className="pt-2"
                  style={{ color: "#333741" }}
                >
                  <Text
                    className="pt-2"
                    fontSize="13px"
                    text="Provider Login"
                  />
                </Link> */}
            </Col>
            {/* </> */}
          </Row>
        </Col>
        <Col>
          <Row className="hipaaresp">
            <Col xs="auto">
              <img src={HIPPA} alt="" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center text-center">
        <Text
          className="pt-2"
          fontSize="12px"
          text="Copyright © 2023 Brell Health Inc."
        />
        <Text
          className="pt-2"
          fontSize="12px"
          text="Need help? +1 (844) 516-7316 | hello@brell.health"
        />
      </Row>
    </Container>
  );
}
