import { Button as Bootstrapbtn, ButtonProps } from "react-bootstrap";
import { THEME } from "../../const";
import nounlockedlock from "./Img/lock.svg";
interface IButton extends ButtonProps {
  text?: any;
  width?: any;
  primarybtn?: boolean;
  href?: any;
  onClick?: any;
  small?: boolean;
  mobileBtn?: boolean;
  icon?: boolean;
  secondary?: boolean;
}

export default function Button({
  text,
  primarybtn,
  onClick,
  href,
  small,
  mobileBtn,
  icon,
  secondary,
  width,
  ...restofprops
}: IButton) {
  return (
    <Bootstrapbtn
      className=""
      href={href}
      onClick={onClick}
      style={{
        backgroundColor: primarybtn
          ? `${THEME.PRIMARY}`
          : secondary
          ? `${THEME.WHITE}`
          : "transparent",
        //   padding: small ? "23px 30px 23px 30px" : "17px 40px 17px 40px",
        borderTopLeftRadius: "25px",
        borderBottomRightRadius: "25px",
        // fontFamily: "IBMPlexSans",
        lineHeight: "1.05",
        fontSize: small ? "16px" : "20px",
        height: small ? "46px" : "60px",
        width: width ? width : small ? "200px" : "300px",
        // border: primarybtn ? "" : `3px Solid ${THEME.PRIMARY}`,
        border: secondary ? "1px solid black" : "none",
        color: primarybtn ? "white" : secondary ? "black" : `${THEME.PRIMARY}`,
        transform: "translate(0px, -7px)",
      }}
      {...restofprops}
    >
      {icon && (
        <img
          src={nounlockedlock}
          style={{ marginRight: "16px", transform: "translate(0px, -2px)" }}
          alt=""
        />
      )}
      {text}
    </Bootstrapbtn>
  );
}
