import { Col, Row } from "react-bootstrap";
import Text from "../../Components/Text/Text";
import bgimg from "./Img/bgimg.jpeg";
import { THEME } from "../../const";
import { motion } from "framer-motion";
import data from "../../Data/Data";
import { PopupButton } from "@typeform/embed-react";

import "./ClinicHero.css";
import MediaQuery from "react-responsive";
export default function ClinicHero() {
  return (
    // <div style={{backgroundColor: '#F8F8F8'}}>
    <div style={{}}>
    <div
        style={{
          paddingTop: "35vh",
          backgroundImage: `url(${bgimg}) `,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderBottomLeftRadius: "250px",
          overflowX: "hidden",
        }}
      >
        <Row>
          <Col lg={8} md={8}>
            <div
              style={{
                // backgroundColor: "rgb(131 98 170 / 82%)",
                backgroundColor: "rgb(240 101 165 / 88%)",
                borderTopRightRadius: "250px",
                padding: "40px",
                opacity: "20px",
                borderBottomLeftRadius: "250px",
              }}
            >
              
              <div
                className="resClinicHeroPadding"
                style={{
                  paddingTop: "70px",
                  paddingBottom: "70px",
                  paddingLeft: "70px",
                  paddingRight: "70px",
                }}
              >
                <motion.div
                  initial={{ x: "-5vw" }}
                  animate={{ x: 0 }}
                  transition={{
                    type: "spring",
                    duration: 3,
                  }}
                >
                  <MediaQuery maxWidth={428}>
                    <Text
                      className="respconsultitle whitespace"
                      h2
                      bold
                      color={THEME.WHITE}
                      text={data.Clinic.Hero.title}
                    />
                  </MediaQuery>
                  <MediaQuery minWidth={429}>
                    <Text
                      className="respconsultitle whitespace"
                      h2
                      bold
                      color={THEME.WHITE}
                      text={data.Clinic.Hero.title}
                    />
                  </MediaQuery>
                </motion.div>
                <motion.div
                  initial={{ x: "5vw" }}
                  animate={{ x: 0 }}
                  transition={{
                    type: "spring",
                    duration: 3,
                  }}
                >
                  <Text
                    className="pt-4 respconsuldesription"
                    regular
                    color={THEME.WHITE}
                    text={data.Clinic.Hero.sub}
                  />
                  <Row style={{ display: "flex", paddingTop: "3rem" }}>
                    <Col></Col>
                    <Col style={{ justifyContent: "flex-end" }}>
                      <PopupButton
                        id="wqyOGCkj"
                        // style={{ fontSize: 20 }}
                        className="my-button"
                        style={{
                          backgroundColor: `${THEME.PRIMARY}`,
                          borderTopLeftRadius: "25px",
                          borderBottomRightRadius: "25px",
                          fontFamily: "IBMPlexSans-Regular",
                          fontSize: "20px",
                          height: "60px",
                          width: "300px",
                          border: "1px solid black",
                          color: `white`,
                          transform: "translate(0px, -7px)",
                        }}
                      >
                        Request Demo
                      </PopupButton>
                    </Col>
                  </Row>
                </motion.div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
