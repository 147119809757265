import React from "react";
import { Container } from "react-bootstrap";
import Text from "../../Components/Text/Text";
import data from "../../Data/Data";
interface privacyProps {
  title?: boolean;
}

export default function TermsandCondition({ title }: privacyProps) {
  const privacy = [
    {
      title: data.Privacy.INTRODUCTION.title,
      description: data.Privacy.INTRODUCTION.description,
    },
    {
      title: data.Privacy.HOWISPATIENTPRIVACYPROTECTED.title,
      description: data.Privacy.HOWISPATIENTPRIVACYPROTECTED.description,
    },
    {
      title: data.Privacy.HOWDOWEUSEANDDISCLOSEHEALTHINFORMATION.title,
      description:
        data.Privacy.HOWDOWEUSEANDDISCLOSEHEALTHINFORMATION.description,
      listTitle: data.Privacy.HOWDOWEUSEANDDISCLOSEHEALTHINFORMATION.li,
    },

    {
      title: data.Privacy.THEHEALTHCAREPROFESSIONALS.title,
      description: data.Privacy.THEHEALTHCAREPROFESSIONALS.description,
    },
    {
      listTitle: data.Privacy.THEHEALTHCAREPROFESSIONALS.li,
    },

    {
      title: data.Privacy.PATIENTSRIGHTS.title,
      description: data.Privacy.PATIENTSRIGHTS.description,
    },
    {
      listTitle: data.Privacy.PATIENTSRIGHTS.li,
    },

    {
      title: data.Privacy.COLLECTIONOFYOURINFORMATION.title,
      description: data.Privacy.COLLECTIONOFYOURINFORMATION.description,
    },
    {
      title: data.Privacy.PersonalData.title,
      description: data.Privacy.PersonalData.description,
    },
    {
      title: data.Privacy.PatientInformation.title,
      description: data.Privacy.PatientInformation.description,
    },
    {
      title: data.Privacy.ExaminationInformation.title,
      description: data.Privacy.ExaminationInformation.description,
    },
    {
      title: data.Privacy.DerivativeData.title,
      description: data.Privacy.DerivativeData.description,
    },
    {
      title: data.Privacy.FinancialData.title,
      description: data.Privacy.FinancialData.description,
    },
    {
      title: data.Privacy.DataFromSocialNetworks.title,
      description: data.Privacy.DataFromSocialNetworks.description,
    },
    {
      title: data.Privacy.MobileDeviceData.title,
      description: data.Privacy.MobileDeviceData.description,
    },
    {
      title: data.Privacy.ThirdPartyData.title,
      description: data.Privacy.ThirdPartyData.description,
    },
    {
      title: data.Privacy.DataFromContests.title,
      description: data.Privacy.DataFromContests.description,
    },
    {
      title: data.Privacy.RETENTIONOFYOURINFORMATION.title,
      description: data.Privacy.RETENTIONOFYOURINFORMATION.description,
    },
    {
      title: data.Privacy.USEOFYOURINFORMATION.title,
      description: data.Privacy.USEOFYOURINFORMATION.description,
      listTitle: data.Privacy.USEOFYOURINFORMATION.li,
    },
    {
      title: data.Privacy.DISCLOSUREOFYOURINFORMATION.title,
      description: data.Privacy.DISCLOSUREOFYOURINFORMATION.description,
    },
    {
      title: data.Privacy.ByLawortoProtectRights.title,
      description: data.Privacy.ByLawortoProtectRights.description,
    },
    {
      title: data.Privacy.ThirdPartyServiceProviders.title,
      description: data.Privacy.ThirdPartyServiceProviders.description,
    },
    {
      title: data.Privacy.MarketingCommunications.title,
      description: data.Privacy.MarketingCommunications.description,
    },
    {
      title: data.Privacy.InteractionswithOtherUsers.title,
      description: data.Privacy.InteractionswithOtherUsers.description,
    },
    {
      title: data.Privacy.OnlinePostings.title,
      description: data.Privacy.OnlinePostings.description,
    },
    {
      title: data.Privacy.ThirdPartyAdvertisers.title,
      description: data.Privacy.ThirdPartyAdvertisers.description,
    },
    {
      title: data.Privacy.Affiliates.title,
      description: data.Privacy.Affiliates.description,
    },
    {
      title: data.Privacy.BusinessPartners.title,
      description: data.Privacy.BusinessPartners.description,
    },
    {
      title: data.Privacy.OtherThirdParties.title,
      description: data.Privacy.OtherThirdParties.description,
    },
    {
      title: data.Privacy.SaleorBankruptcy.title,
      description: data.Privacy.SaleorBankruptcy.description,
    },
    {
      title: data.Privacy.TRACKINGTECHNOLOGIES.title,
    },
    {
      title: data.Privacy.CookiesandWebBeacons.title,
      description: data.Privacy.CookiesandWebBeacons.description,
    },
    {
      title: data.Privacy.InternetBasedAdvertising.title,
      description: data.Privacy.InternetBasedAdvertising.description,
    },
    {
      title: data.Privacy.WebsiteAnalytics.title,
      description: data.Privacy.WebsiteAnalytics.description,
    },
    {
      title: data.Privacy.THIRDPARTYWEBSITES.title,
      description: data.Privacy.THIRDPARTYWEBSITES.description,
    },
    {
      title: data.Privacy.SECURITYOFYOURINFORMATION.title,
      description: data.Privacy.SECURITYOFYOURINFORMATION.description,
    },
    {
      title: data.Privacy.POLICYFORCHILDREN.title,
      description: data.Privacy.POLICYFORCHILDREN.description,
    },
    {
      title: data.Privacy.CONTROLSFORDONOTTRACKFEATURES.title,
      description: data.Privacy.CONTROLSFORDONOTTRACKFEATURES.description,
    },
    {
      title: data.Privacy.YOURGDPRRIGHTS.title,
      description: data.Privacy.YOURGDPRRIGHTS.description,
    },
    {
      listTitle: data.Privacy.YOURGDPRRIGHTS.li,
    },

    {
      title: data.Privacy.OPTIONSREGARDINGYOURINFORMATION.title,
    },
    {
      title: data.Privacy.AccountInformation.title,
      description: data.Privacy.AccountInformation.description,
    },
    {
      listTitle: data.Privacy.AccountInformation.li,
    },

    {
      description: data.Privacy.AccountInformation.description2,
    },
    {
      title: data.Privacy.EmailsandCommunications.title,
      description: data.Privacy.EmailsandCommunications.description,
    },
    {
      listTitle: data.Privacy.EmailsandCommunications.li,
    },

    {
      description: data.Privacy.EmailsandCommunications.description2,
    },
    {
      title: data.Privacy.CONTACTUS.title,
      description: data.Privacy.CONTACTUS.description,
    },
  ];
  return (
    <Container className="mt-5 ">
      <Text className="text-center mb-5" h3 bold text={"PRIVACY POLICY"} />
      {privacy.map(({ title, description, listTitle }, i) => (
        <React.Fragment key={i}>
          {title && (
            <div style={{ marginTop: "2em" }}>
              <Text className="mt-3" fontSize="30px" bold text={title} />
            </div>
          )}
          {description && (
            <Text className="mt-2 whitespace" small text={description} />
          )}
          {listTitle && (
            <ul>
              {listTitle.map((value: any, j) => (
                <li key={j}>
                  <div style={{ marginBottom: "1em" }}>
                    <Text className="mt-2 whitespace" small text={value} />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </React.Fragment>
      ))}
    </Container>
  );
}
