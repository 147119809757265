import { Col, Container, Row } from "react-bootstrap";
import Text from "../../Components/Text/Text";
import { THEME } from "../../const";
import bgimg from "./Img/bgimg.png";
import MediaQuery from "react-responsive";
import data from "../../Data/Data";

export default function ExpertOpinion() {
  return (
    <>
      <div className="text-center " style={{ backgroundColor: "#FFFFFF" }}>
        <Container
          fluid
          style={{
            paddingTop: "30vh",
            paddingBottom: "25vh",
            backgroundImage: `url(${bgimg}) `,
            backgroundPosition: "TOP",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Row className="justify-content-center">
            <MediaQuery minWidth={501}>
              <Col lg={8}>
                <div>
                  <Text
                    h2
                    bold
                    color={THEME.BLACK}
                    text={data.Radiologist["What is it"].title}
                  />
                </div>
                <br/>
                <br/>
                <div className="pt-5">
                  <Text
                    medium
                    color={THEME.BLACK}
                    text={data.Radiologist["What is it"].sub}
                  />
                </div>
                <div className="pt-5">
                  <Text
                    medium
                    color={THEME.BLACK}
                    text={data.Radiologist["What is it"].sub2}
                  />
                </div>
                {/* <div className="pt-5">
                  <Text
                    medium
                    color={THEME.BLACK}
                    text={data.Radiologist["What is it"].sub3}
                  />
                </div> */}
              </Col>
            </MediaQuery>

            {/* mob view start */}
            <MediaQuery maxWidth={500}>
              <Col lg={8}>
                <div>
                  <Text
                    className=""
                    fontSize="34px"
                    bold
                    color={THEME.BLACK}
                    text={data.Radiologist["What is it"].title}
                  />
                </div>
                <div className="pt-5">
                  <Text
                    small
                    color={THEME.BLACK}
                    text={data.Radiologist["What is it"].sub}
                  />
                </div>
                <div className="pt-5">
                  <Text
                    small
                    color={THEME.BLACK}
                    text={data.Radiologist["What is it"].sub2}
                  />
                </div>
                {/* <div className="pt-5">
                  <Text
                    small
                    color={THEME.BLACK}
                    text={data.Radiologist["What is it"].sub3}
                  />
                </div> */}
              </Col>
            </MediaQuery>
            {/* mob view end */}
          </Row>
        </Container>
      </div>
    </>
  );
}
