// import { PopupButton } from "@typeform/embed-react";
import Hamburger from "hamburger-react";
import { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { Link, useLocation } from "react-router-dom";

import { LINK } from "../../const";
import Button from "../Button/Button";
import "./Header.css";
// import logo from "./Img/logo.svg";
// import logo from "./Img/brell_logo_05x.png";
import logo from "./Img/brell_logo_05x.jpg";

interface IHeader {
  handleShow?: any;
}

export default function Header({ handleShow }: IHeader) {
  const menu = [
    {
      title: "Brell Platform",
      to: "/",
      open: false,
    },
    {
      title: "Imaging Centers",
      to: "/care-centers/",
      open: false,
    },
    {
      title: "Specialists",
      to: "/specialists/",
      open: false,
    },
    {
      title: "Patients & Advocates",
      to: "/patients/",
      open: false,
    },
    {
      title: "Blog",
      to: "/blog/",
      open: true,
    },
    // {
    //   title: "Expert Consults",
    //   to: "/consult/",
    // },
    // {
    //   title: "Physicians",
    //   to: "/physicians/",
    // },
    // {
    //   title: <>Pricing</>,
    //   to: "/pricing",
    // },
  ];
  const [headerColor, setHeaderColor] = useState(false);

  const changebackground = () => {
    window.scrollY > 0 ? setHeaderColor(true) : setHeaderColor(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changebackground);
  });
  const [tabIdd, setTabId] = useState(true);
  const [show, setShow] = useState<boolean>(false);

  const location = useLocation();
  // console.log("location", location?.pathname);

  return (
    <>
      {/* <div
        style={{
          // backgroundColor: "rgba(240, 101, 165, 0.88)",
          backgroundColor: "rgba(131, 98, 170, 0.82)",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: ".5rem",
          paddingBottom: ".5rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          // fontSize: "14px",
        }}
      >
        Are you at RSNA 2022? Get to know the Brell's team better — either in
        person or virtually.
        <PopupButton
          id="wqyOGCkj"
          // style={{ fontSize: 20 }}
          className="my-button"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.0)",
            // fontSize: "14px",
            textDecoration: "underline",
            fontWeight: "700",
            border: "none",
            color: `white`,
          }}
        >
          Let's meet.
        </PopupButton>
      </div> */}

      <Navbar
        expanded={show}
        expand="lg"
        sticky="top"
        fixed="top"
        className={
          headerColor
            ? "changeheader pb-3 bottomShadow"
            : "defaultheader pb-3 bottomShadow"
        }
      >
        <Container fluid>
          <Col>
            <a href="/">
              <img
                alt="Logo"
                height="50px"
                src={logo}
                style={{ marginTop: "16px", marginLeft: "32px" }}
              />
            </a>
          </Col>
          <Col className="menuColumn">
            <Navbar.Toggle aria-controls="navbarScroll">
              <Hamburger color="black" toggled={show} toggle={setShow} />
            </Navbar.Toggle>

            <Navbar.Collapse id="navbarScroll">
              <Nav className="mr-auto">
                {menu.map(({ title, to, open }, index) => {
                  return (
                    <Row
                      className="justify-content-center text-center align-items-center"
                      key={index}
                    >
                      <Col xs="auto">
                        <Link
                          onClick={() => {
                            setTabId(tabIdd);
                            setShow(false);
                            if (open === true) {
                              window.open(to, "_blank");
                            }
                          }}
                          className={
                            location?.pathname === to ? "active-now" : "active"
                          }
                          style={{
                            color: "black",
                            textDecoration: "none",
                            textDecorationColor: "#8362aa",
                          }}
                          to={open !== true ? to : ""}
                        >
                          <MediaQuery minWidth={992}>
                            <p
                              // className={"active"}
                              className={
                                location?.pathname === to
                                  ? "active-now"
                                  : "active"
                              }
                              style={{
                                padding: "0px",
                                marginRight: "50px",
                                marginBottom: "0px",
                                fontSize: "16px",
                                fontFamily: "IBMPlexSans-Regular",
                                lineHeight: "1.3",
                              }}
                            >
                              {title}
                            </p>
                          </MediaQuery>
                          <MediaQuery maxWidth={991}>
                            <p
                              // className={"active"}
                              className={
                                location?.pathname === to
                                  ? "active-now"
                                  : "unset"
                              }
                              style={{
                                padding: "0px",
                                marginBottom: "0px",
                                fontSize: "16px",
                                fontFamily: "IBMPlexSans-Regular",
                                // lineHeight: '1.3'
                              }}
                            >
                              {title}
                            </p>
                          </MediaQuery>
                        </Link>
                      </Col>
                    </Row>
                  );
                })}
                <MediaQuery maxWidth={970}>
                  <Row className="justify-content-center text-center">
                    <Col xs={10}>
                      <br />
                      {/* <Button
                          primarybtn
                          small
                          text="Get Expert Opinion Now"
                          onClick={(e: { preventDefault: () => void }) => {
                            e.preventDefault();
                            window.location.href = `${LINK.Webflow}`;
                          }}
                        /> */}

                      <div className="mt-3">
                        <Button
                          secondary
                          small
                          icon
                          text="Log in"
                          onClick={(e: { preventDefault: () => void }) => {
                            e.preventDefault();
                            window.location.href = `${LINK.Login}`;
                          }}
                        />
                      </div>
                      <a href="/">
                        <img
                          className="pt-3 mt-5"
                          alt="Logo"
                          src={logo}
                          height="70px"
                        />
                      </a>
                    </Col>
                  </Row>
                </MediaQuery>
              </Nav>
            </Navbar.Collapse>
          </Col>
          <MediaQuery minWidth={993}>
            <Row id="zLogo" style={{ paddingTop: "24px", marginRight: "5rem" }}>
              <Col style={{ justifyContent: "end" }}>
                {/* <Button
                  primarybtn
                  small
                  text="Get Expert Opinion Now"
                  onClick={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    window.location.href = `${LINK.Webflow}`;
                  }}
                /> */}
              </Col>
              <Col style={{ justifyContent: "end" }}>
                <Button
                  secondary
                  small
                  icon
                  text="Log in"
                  onClick={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    window.location.href = `${LINK.Login}`;
                  }}
                />
              </Col>
            </Row>
          </MediaQuery>
        </Container>
      </Navbar>
    </>
  );
}
