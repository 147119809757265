import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Box from "../../Components/Box/Box";
import Text from "../../Components/Text/Text";
// import data from "../../Data/Data";
import "./Features.css";
// import nounmedical from "./Img/noun-medical-4701001 1.png";

interface FeaturesProps {
  copy: any;
}

interface copy {
  icon: any;
  title: string;
  description: string;
  bigBox?: boolean;
}

export default function Features({ copy }: FeaturesProps) {
  return (
    <Container className="mt-4 pb-5 mb-5">
      <div className="text-center pb-2">
        <Text bold h2 className="respFeatureheading" text={"Features"} />
      </div>
      <Row>
        {copy.map(
          ({ icon, title, description, bigBox }: copy, index: number) => (
            <Col xl={4} lg={6} className="mt-4" key={index}>
              <Box
                icon={icon}
                title={title}
                description={description}
                bigBox={bigBox}
              />
            </Col>
          )
        )}
        {/* <Col>
          <div
            className="shadow mt-4 p-5"
            style={{ backgroundColor: "white", borderRadius: "10px" }}
          >
            <Row>
              <Col lg={1}>
                <img src={nounmedical} alt="" />
              </Col>
              <Col lg={11}>
                <Text bold text={data.Radiologist.Features.feature7title} />
                <Text
                  className="pt-2"
                  small
                  text={data.Radiologist.Features.feature7sub}
                />
              </Col>
            </Row>
          </div>
        </Col> */}
      </Row>
    </Container>
  );
}
