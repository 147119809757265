import video from "./Img/noun-video-4698136 1.svg";
import nouncustomize from "./Img/noun-customize-3961686 1.svg";
import noundashboard from "./Img/noun-dashboard-4700301-1.svg";
import nouneasypayment from "./Img/noun-easy-payment-3786337 1.svg";
import nounfile from "./Img/noun-file-4700408-1.svg";
import nounsecurity from "./Img/noun-security-4689768-1.svg";
import data from "../../Data/Data";
import medical from "./Img/noun-medical-4701001 1.png"

export const copyPlatform = [
  {
    icon: medical,
    title: data.Platform.Features.feature1title,
    description: data.Platform.Features.feature1sub,
  },
  {
    icon: noundashboard,
    title: data.Platform.Features.feature2title,
    description: data.Platform.Features.feature2sub,
  },
  {
    icon: nounfile,
    title: data.Platform.Features.feature3title,
    description: data.Platform.Features.feature3sub,
  },
  {
    icon: nouneasypayment,
    title: data.Platform.Features.feature4title,
    description: data.Platform.Features.feature4sub,
    // bigBox: true,
  },
  {
    icon: video,
    title: data.Platform.Features.feature5title,
    description: data.Platform.Features.feature5sub,
    // bigBox: true,
  },
  {
    icon: nounsecurity,
    title: data.Platform.Features.feature6title,
    description: data.Platform.Features.feature6sub,
    // bigBox: true,
  },
];

export const copyRadiologists = [
  // {
  //   icon: nouneasypayment,
  //   title: data.Radiologist.Features.feature1title,
  //   description: data.Radiologist.Features.feature1sub,
  // },
  // {
  //   icon: nouncustomize,
  //   title: data.Radiologist.Features.feature2title,
  //   description: data.Radiologist.Features.feature2sub,
  // },
  {
    icon: nounfile,
    title: data.Radiologist.Features.feature3title,
    description: data.Radiologist.Features.feature3sub,
  },
  {
    icon: noundashboard,
    title: data.Radiologist.Features.feature4title,
    description: data.Radiologist.Features.feature4sub,
    bigBox: true,
  },
  {
    icon: video,
    title: data.Radiologist.Features.feature5title,
    description: data.Radiologist.Features.feature5sub,
    bigBox: true,
  },
  {
    icon: nounsecurity,
    title: data.Radiologist.Features.feature6title,
    description: data.Radiologist.Features.feature6sub,
    bigBox: true,
  },
];


export const copyPatients = [
  {
    icon: nounfile,
    title: data.Radiologist.Features.feature3title,
    description: data.Radiologist.Features.feature3sub,
  },
  {
    icon: noundashboard,
    title: data.Radiologist.Features.feature4title,
    description: data.Radiologist.Features.feature4sub,
    bigBox: true,
  },
  {
    icon: video,
    title: data.Radiologist.Features.feature5title,
    description: data.Radiologist.Features.feature5sub,
    bigBox: true,
  },
  {
    icon: nounsecurity,
    title: data.Radiologist.Features.feature6title,
    description: data.Radiologist.Features.feature6sub,
    bigBox: true,
  },
];

