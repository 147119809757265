import { Widget } from "@typeform/embed-react";
import "./Records.css";

export default function Records() {
  return (
    <Widget
      id="NyLv6nJg"
      style={{
        padding: '2rem',
        width: "100%",
        height: "600px",
        fontFamily: "IBMPlexSans-Bold",
        fontSize: "16px",
      }}
      className="my-form"
    />
  );
}
