import { Col, Container, Row } from "react-bootstrap";
import Text from "../../Components/Text/Text";
import rightimg from "./Img/Group 285 (1).png";
import pinkicon from "./Img/pinkicon.svg";
import yellowicon from "./Img/yellowicon.svg";
import purpleicon from "./Img/purpleicon.svg";
import quo from "./Img/quo.svg";
import React from "react";
import data from "../../Data/Data";

export default function Patients() {
  const list = [
    {
      icon: pinkicon,
      title: data.Patients.Benefits.items.item1title,
      description: data.Patients.Benefits.items.item1sub,
    },
    {
      icon: purpleicon,
      title: data.Patients.Benefits.items.item2title,
      description: data.Patients.Benefits.items.item2sub,
    },
    {
      icon: yellowicon,
      title: data.Patients.Benefits.items.item3title,
      description: data.Patients.Benefits.items.item3sub,
    },
  ];
  return (
    <>
      <Container >
        <br/>
        <br/>
        <br/>
        <Text className="text-center pt-5 pb-5" h2 bold text={data.Patients.Benefits.title} />
        <Row className="pt-5">
          <Col lg={5} md={4}>
            <img
              style={{
                position: "absolute",
                left: "0",
                zIndex: "-1",
                // maxWidth: "100%",
                maxHeight: "60rem",
              }}
              src={rightimg}
              alt=""
            />
          </Col>
          <Col lg={7} md={8}>
            <div style={{ paddingBottom: "0px" }}>
              <div style={{ marginBottom: "-40px", marginLeft: "-20px" }}>
                <img src={quo} alt="" />
              </div>
              <Text h3 bold text={data.Patients.Benefits.sub} />
            </div>

            {list.map(({ title, icon, description }, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className="p-3 shadow"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                      marginTop: "50px",
                    }}
                  >
                    <Row className="align-items-center">
                      <Col lg={3}>
                        <div className="text-center">
                          <img src={icon} alt="" />
                        </div>
                      </Col>
                      <Col lg={9}>
                        <Text regular bold text={title} />
                        <Text small text={description} />
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              );
            })}
          </Col>
        </Row>
      </Container>
    </>
  );
}
