import React from "react";
import { Container } from "react-bootstrap";
import Text from "../../Components/Text/Text";
import data from "../../Data/Data";
import "./Terms&Condition.css";
interface termProps {
  title?: boolean;
}

export default function TermsandCondition({ title }: termProps) {
  const terms = [
    {
      title: data.termsandCondition.AgreementtoTerms.title,
      description: data.termsandCondition.AgreementtoTerms.description,
    },
    {
      description: data.termsandCondition.AgreementtoTerms2.description,
    },
    {
      description: data.termsandCondition.AgreementtoTerms3.description,
    },
    {
      title: data.termsandCondition.PrivacyPolicy.title,
      description: data.termsandCondition.PrivacyPolicy.description,
    },
    {
      title: data.termsandCondition.Additionalterms.title,
      description: data.termsandCondition.Additionalterms.description,
    },

    {
      title: data.termsandCondition.Registration.title,
      description: data.termsandCondition.Registration.description,
    },

    {
      title: data.termsandCondition.Registrationobligations.title,
      description: data.termsandCondition.Registrationobligations.description,
    },
    {
      title: data.termsandCondition.UserAccountPasswordandSecurity.title,
      description:
        data.termsandCondition.UserAccountPasswordandSecurity.description,
    },
    {
      title: data.termsandCondition.Deletingyouraccount.title,
      description: data.termsandCondition.Deletingyouraccount.description,
    },
    {
      title: data.termsandCondition.NoTreatmentOffered.title,
      description: data.termsandCondition.NoTreatmentOffered.description,
    },
    {
      description: data.termsandCondition.NoTreatmentOffered2.description,
    },
    {
      description: data.termsandCondition.NoTreatmentOffered3.description,
    },
    {
      title: data.termsandCondition.HealthRelatedContent.title,
      description: data.termsandCondition.HealthRelatedContent.description,
    },
    {
      description: data.termsandCondition.HealthRelatedContent2.description,
    },
    {
      title: data.termsandCondition.AccessCompliancewithlaws.title,
      description: data.termsandCondition.AccessCompliancewithlaws.description,
    },
    {
      title: data.termsandCondition.Storageofdata.title,
      description: data.termsandCondition.Storageofdata.description,
    },
    {
      title: data.termsandCondition.Payment.title,
      description: data.termsandCondition.Payment.description,
    },
    {
      title: data.termsandCondition.FeesRefunds.title,
      description: data.termsandCondition.FeesRefunds.description,
    },
    {
      title: data.termsandCondition.Paymentauthorisation.title,
      description: data.termsandCondition.Paymentauthorisation.description,
    },
    {
      title: data.termsandCondition.PaymentProcessing.title,
      description: data.termsandCondition.PaymentProcessing.description,
    },
    {
      title: data.termsandCondition.Paymentprocessingforfinancingpayments.title,
      description:
        data.termsandCondition.Paymentprocessingforfinancingpayments
          .description,
    },
    {
      title: data.termsandCondition.ConditionsofAccessandUse.title,
    },
    {
      title: data.termsandCondition.Prohibitions.title,
      description: data.termsandCondition.Prohibitions.description,
    },
    {
      title: data.termsandCondition.IntellectualProperty.title,
      description: data.termsandCondition.IntellectualProperty.description,
    },
    {
      title: data.termsandCondition.DisclaimerofLiability.title,
      description: data.termsandCondition.DisclaimerofLiability.description,
    },
    {
      title: data.termsandCondition.LinkingtothisWebsite.title,
      description: data.termsandCondition.LinkingtothisWebsite.description,
    },
    {
      title: data.termsandCondition.Disclaimerastoownershipoftrademarks.title,
      description:
        data.termsandCondition.Disclaimerastoownershipoftrademarks.description,
    },
    {
      title: data.termsandCondition.Indemnity.title,
      description: data.termsandCondition.Indemnity.description,
    },
    {
      title: data.termsandCondition.Variation.title,
      description: data.termsandCondition.Variation.description,
    },

    {
      title: data.termsandCondition.Invalidity.title,
      description: data.termsandCondition.Invalidity.description,
    },
    {
      title: data.termsandCondition.Complaints.title,
      description: data.termsandCondition.Complaints.description,
    },

    {
      title: data.termsandCondition.Termination.title,
      description: data.termsandCondition.Termination.description,
    },
    {
      title: data.termsandCondition.Waiver.title,
      description: data.termsandCondition.Waiver.description,
    },
    {
      title: data.termsandCondition.GoverningLawJurisdiction.title,
      description: data.termsandCondition.GoverningLawJurisdiction.description,
    },
    {
      title: data.termsandCondition.EntireAgreement.title,
      description: data.termsandCondition.EntireAgreement.description,
    },
    {
      title: data.termsandCondition.MutualArbitrationProvision.title,
      listTitle: data.termsandCondition.MutualArbitrationProvision.li,
    },

    {
      title: data.termsandCondition.CONTACTUS.title,
      description: data.termsandCondition.CONTACTUS.description,
    },
  ];
  return (
    <>
      <Container className="mt-5 ">
        <Text
          className="text-center mb-5"
          h3
          bold
          text={<>TERMS OF SERVCE</>}
        />
        {terms.map(({ title, description, listTitle }, i) => (
          <React.Fragment key={i}>
            {title && (
              <div style={{ marginTop: "2em" }}>
                <Text className="mt-3" fontSize="30px" bold text={title} />
              </div>
            )}

            {description && (
              <Text className="mt-2 whitespace" small text={description} />
            )}
            {listTitle && (
              <ul>
                {listTitle.map((value: any, j) => (
                  <li key={j}>
                    <div style={{ marginBottom: "1em" }}>
                      <Text className="mt-2 whitespace" small text={value} />
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </React.Fragment>
        ))}
      </Container>
    </>
  );
}
