import MediaQuery from "react-responsive";
// import Faq from "../../Sections/Faq/Faq";
import Features from "../../Sections/Features/Features";
import Featuresmobile from "../../Sections/Features/Featuresmobile";
// import PricingPlan from "../../Sections/PricingPlan/PricingPlan";
// import PricingPlanmobile from "../../Sections/PricingPlan/PricingPlanmobile";
import RadiologyHowitworks from "../../Sections/RadiologyHowitworks/RadiologyHowitworks";
import Radiologychangingmobile from "../../Sections/Radiologyischanging/Radiologychangingmobile";
import Radiologyischanging from "../../Sections/Radiologyischanging/Radiologyischanging";
import InsightsRads from "../../Sections/InsightsRads/InsightsRads";
import ExpertOpinion from "../../Sections/ExpertOpinion/ExpertOpinion";
import { copyRadiologists } from "../../Sections/Features/copy";
// import { copyfaq1 } from "../../Sections/Faq/copy";
import RadiologistHero from "../../Sections/RadiologistHero/RadiologistHero";
// import Contact from "../../Sections/Contact/Contact";

export default function Radiologists() {
  return (
    <>
      <RadiologistHero />

      {/* Radiology changing */}
      <MediaQuery maxWidth={766}>
        <Radiologychangingmobile />
      </MediaQuery>
      <MediaQuery minWidth={767}>
        <Radiologyischanging />
      </MediaQuery>
      <br />
      <br />
      <br />
      <ExpertOpinion />

      <RadiologyHowitworks />

      <MediaQuery maxWidth={991}>
        <Featuresmobile copy={copyRadiologists} />
      </MediaQuery>
      <MediaQuery minWidth={992}>
        <Features copy={copyRadiologists} />
      </MediaQuery>

      <InsightsRads />

      {/* <Radiologyconsultations /> */}
      {/* <MediaQuery minWidth={992}>
        <PricingPlan />
      </MediaQuery>
      <MediaQuery maxWidth={991}>
        <PricingPlanmobile />
      </MediaQuery> */}

      {/* <Faq copy={copyfaq1} /> */}

      {/* <Contact /> */}
    </>
  );
}
