import { Col, Row } from "react-bootstrap";
import Text from "../../Components/Text/Text";
import bgimg from "./Img/bgimg.jpg";
import { THEME } from "../../const";
import { motion } from "framer-motion";
import data from "../../Data/Data";
// import { PopupButton } from "@typeform/embed-react";
import Button from "../../Components/Button/Button";
import { LINK } from "../../const";

import "./PatientsHero.css";
import MediaQuery from "react-responsive";
export default function PatientsHero() {
  return (
    <>
      <div
        style={{
          paddingTop: "40vh",
          backgroundImage: `url(${bgimg}) `,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderBottomLeftRadius: "250px",
          overflowX: "hidden",
        }}
      >
        <Row>
          <Col lg={8} md={8}>
            <div
              style={{
                backgroundColor: "rgb(131 98 170 / 82%)",
                borderTopRightRadius: "250px",
                padding: "40px",
                opacity: "20px",
                borderBottomLeftRadius: "250px",
              }}
            >
              <div
                className="resPatientsHeroPadding"
                style={{
                  padding: "70px",
                }}
              >
                <motion.div
                  initial={{ x: "-5vw" }}
                  animate={{ x: 0 }}
                  transition={{
                    type: "spring",
                    duration: 3,
                  }}
                >
                  <MediaQuery maxWidth={428}>
                    <Text
                      className="respconsultitle whitespace"
                      h2
                      bold
                      color={THEME.WHITE}
                      text={data.Patients.Hero.mobiletitle}
                    />
                  </MediaQuery>
                  <MediaQuery minWidth={429}>
                    <Text
                      className="respconsultitle whitespace"
                      h2
                      bold
                      color={THEME.WHITE}
                      text={data.Patients.Hero.title}
                    />
                  </MediaQuery>
                </motion.div>
                <motion.div
                  initial={{ x: "5vw" }}
                  animate={{ x: 0 }}
                  transition={{
                    type: "spring",
                    duration: 3,
                  }}
                >
                  <Text
                    className="pt-4 respconsuldesription"
                    regular
                    color={THEME.WHITE}
                    text={data.Patients.Hero.sub}
                  />
                  <Row
                    style={{
                      display: "flex",
                      paddingTop: "3rem",
                      paddingLeft: "5rem",
                    }}
                  >
                    <Col style={{ margin: "12px" }}>
                      <Button
                        secondary
                        // icon
                        text="Access My Records"
                        onClick={(e: { preventDefault: () => void }) => {
                          e.preventDefault();
                          window.location.href = `${LINK.clinics}`;
                        }}
                      />
                    </Col>
                    <Col>
                      <Button
                        primarybtn
                        style={{
                          backgroundColor: `${THEME.PRIMARY}`,
                          borderTopLeftRadius: "25px",
                          borderBottomRightRadius: "25px",
                          fontFamily: "IBMPlexSans-Regular",
                          fontSize: "20px",
                          height: "60px",
                          width: "300px",
                          border: "0px",
                          
                        }}
                        // small
                        text="Get Expert Opinion Now"
                        onClick={(e: { preventDefault: () => void }) => {
                          e.preventDefault();
                          window.location.href = `${LINK.Webflow}`;
                        }}
                      />
                      {/* <PopupButton
                      id="wqyOGCkj"
                      // style={{ fontSize: 20 }}
                      className="my-button"
                      style={{
                        backgroundColor: `${THEME.PRIMARY}`,
                        borderTopLeftRadius: "25px",
                        borderBottomRightRadius: "25px",
                        fontFamily: "IBMPlexSans-Regular",
                        fontSize: "20px",
                        height: "60px",
                        width: "300px",
                        border: "1px solid black",
                        color: `white`,
                        transform: "translate(0px, -7px)",
                        margin: '12px'
                      }}
                    >
                      Request Demo
                    </PopupButton> */}
                    </Col>
                  </Row>
                </motion.div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
