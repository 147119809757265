import Howitworks from "../../Sections/Howitworks/Howitworks";
import Howitworksmobile from "../../Sections/Howitworks/Howitworksmobile";
import MediaQuery from "react-responsive";
import data from "../../Data/Data";
import { Link } from "react-router-dom";


export default function PatientsHowitworks() {
  return (
    <>
      {/* How It Works  */}
      <MediaQuery maxWidth={991}>
        <Howitworksmobile
          stepOneText={data.Patients["How It Works"].step1title}
          stepOnedescription={data.Patients["How It Works"].step1sub}
          stepOneLink={data.Patients["How It Works"].step1sublink}
          stepTwoText={data.Patients["How It Works"].step2title}
          stepTwodescription={data.Patients["How It Works"].step2sub}
          stepThreeText={data.Patients["How It Works"].step3title}
          stepThreedescription={data.Patients["How It Works"].step3sub}
          pink
        />
      </MediaQuery>
      <MediaQuery minWidth={992}>
        <Howitworks
          pink
          stepOneText={data.Patients["How It Works"].step1title}
          stepOnedescription={data.Patients["How It Works"].step1sub}
          stepOneLink={data.Patients["How It Works"].step1sublink}
          stepTwoText={data.Patients["How It Works"].step2title}
          stepTwodescription={data.Patients["How It Works"].step2sub}
          stepThreedescription={data.Patients["How It Works"].step3sub}
          stepThreeText={data.Patients["How It Works"].step3title}
        />
      </MediaQuery>
    </>
  );
}
