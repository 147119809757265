import { Card } from "react-bootstrap";
import Text from "../Text/Text";
interface box {
  icon?: any;
  title?: string;
  description?: any;
  bigBox?: boolean;
}

export default function Box({ icon, title, description, bigBox }: box) {
  return (
    <Card
      className="text-center shadow p-5"
      style={{
        border: "none",
        borderRadius: "10px",
        height: bigBox ? "500px" : "500px",
      }}
    >
      <Card.Body style={{

        padding: "0px",
      }}>
        <img src={icon} alt="" height="40px"/>
        <Card.Title className="pt-0">
          <div style={{ paddingTop: "1rem", minHeight: '4rem' }}>
            <Text bold text={title} />
          </div>
        </Card.Title>
        <div style={{ paddingTop: "1rem" }}>
          <Text lineHeight="1.9rem" fontSize={'18px'} text={description} />
        </div>
      </Card.Body>
    </Card>
  );
}
