import { Col, Container, Row } from "react-bootstrap";
import Text from "../../Components/Text/Text";
import iStock from "./Img/img.jpg";
import "./InsightsPlatform.css";
import Slider from "../../Components/Carousel/Carousel";
import img0 from "./Img/10.jpeg";
import img1 from "./Img/7.png";
import img2 from "./Img/4.jpeg";
import img3 from "./Img/6.jpeg";
import data from "../../Data/Data";

const imageList = [img0, img1, img2, img3]
const slideItems = data.Platform["Insights"].slides.map(value => ({heading: value.title, description: value.sub, img: imageList[value.img], link: value.link}))


export default function InsightsPlatform() {
  return (
    <>
      <Container fluid className="">
        <Row
          className="align-items-center p-0 "
          style={{ backgroundColor: "#292929" }}
        >
          <Col
            lg={6}
            className="resHeight"
            style={{
              backgroundImage: `url(${iStock}) `,
              backgroundPosition: "TOP",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></Col>
          <Col lg={6} className=" pb-5 pt-2">
            <Text
              h2
              className="radial text-center resResrcTitle"
              bold
              text={data.Platform["Insights"].title}
            />
            <Row className="justify-content-end p-0">
              <Col lg={8} className="slideWidth p-0">
                <Slider slideItem={slideItems} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
