import React, { useContext } from "react";
import close from "./Img/Group 286.svg";
import open from "./Img/Group 287.svg";
import "./ContextAwareToggle.css";
import {
  AccordionContext,
  Col,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import Text from "../Text/Text";
interface CustomToggle {
  children?: any;
  eventKey?: any;
  callback?: any;
  title?: any;
  icon?: any;
}

export default function ContextAwareToggle({
  children,
  eventKey,
  callback,
  title,
  icon,
}: CustomToggle) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Row className="align-items-center" onClick={decoratedOnClick}>
      {icon ? (
        <React.Fragment>
          <Col xs={2} sm="auto">
            <img src={icon} className="restabIcon" alt="" />
          </Col>
          <Col xs={8}>
            <Text text={title} bold regular className="restabtitle" />
          </Col>
          <Col xs={2} className="text-end">
            {isCurrentEventKey ? (
              <img
                onClick={decoratedOnClick}
                src={close}
                className="restabIcon"
                alt=""
              />
            ) : (
              <img
                onClick={decoratedOnClick}
                src={open}
                className="restabIcon"
                alt=""
              />
            )}
          </Col>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Col xs={2} sm="auto">
            {isCurrentEventKey ? (
              <img
                onClick={decoratedOnClick}
                src={close}
                className="restabIcon"
                alt=""
              />
            ) : (
              <img
                onClick={decoratedOnClick}
                src={open}
                className="restabIcon"
                alt=""
              />
            )}
          </Col>
          <Col xs={10}>
            <Text text={title} bold regular className="restabtitle" />
          </Col>
        </React.Fragment>
      )}
    </Row>
  );
}
