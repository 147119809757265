import { Col, Container, Row } from "react-bootstrap";
import Text from "../../Components/Text/Text";
import rightimg from "./Img/Group 284 (1).png";
import pinkicon from "./Img/pinkicon.svg";
import yellowicon from "./Img/yellowicon.svg";
import data from "../../Data/Data";
import purpleicon from "./Img/purpleicon.svg";
import quo from "./Img/quo.svg";
import MediaQuery from "react-responsive";
import React from "react";

export default function Clinicischanging() {
  const list = [
    {
      icon: pinkicon,
      title: data.Clinic.Benefits.items.item1title,
      description: data.Clinic.Benefits.items.item1sub,
    },
    {
      icon: purpleicon,
      title: data.Clinic.Benefits.items.item2title,
      description: data.Clinic.Benefits.items.item2sub,
    },
    {
      icon: yellowicon,
      title: data.Clinic.Benefits.items.item3title,
      description: data.Clinic.Benefits.items.item3sub,
    },
  ];
  return (
    <>
      <MediaQuery minWidth={387}>
        <Container style={{paddingTop:'5rem', paddingBottom:'10rem'}}>
          <Text
            className="text-center pt-5 pb-5 "
            h2
            bold
            text={data.Clinic.Benefits.title}
          />
          <Row className="pt-5">
            <Col lg={8} md={9}>
              <div style={{ paddingBottom: "80px" }}>
                <div style={{ marginBottom: "-40px", marginLeft: "-20px" }}>
                  <img src={quo} alt="" />
                </div>
                <Text
                  className="whitespace"
                  h3
                  bold
                  text={data.Clinic.Benefits.sub}
                />
              </div>

              {list.map(({ title, icon, description }, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className="p-3 shadow"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        marginTop: "50px",
                      }}
                    >
                      <Row className="align-items-center">
                        <Col lg={3} md={3}>
                          <div className="text-center">
                            <img src={icon} alt="" />
                          </div>
                        </Col>
                        <Col lg={9} md={9}>
                          <Text regular bold text={title} />
                          <Text small text={description} />
                        </Col>
                      </Row>
                    </div>
                  </React.Fragment>
                );
              })}
            </Col>
            <Col lg={4} md={3}>
              <img
                style={{
                  position: "absolute",
                  right: "0",
                  zIndex: "-1",
                }}
                src={rightimg}
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </MediaQuery>
    </>
  );
}
