import MediaQuery from "react-responsive";
// import Faq from "../../Sections/Faq/Faq";
import Features from "../../Sections/Features/Features";
import Featuresmobile from "../../Sections/Features/Featuresmobile";
// import PricingPlan from "../../Sections/PricingPlan/PricingPlan";
// import PricingPlanmobile from "../../Sections/PricingPlan/PricingPlanmobile";
import InsightsPlatform from "../../Sections/InsightsPlatform/InsightsPlatform";
import { copyPlatform } from "../../Sections/Features/copy";
// import { copyfaq1 } from "../../Sections/Faq/copy";
import PlatformHero from "../../Sections/PlatformHero/PlatformHero";
import Offering from "../../Sections/Offering/Offering";
// import Contact from "../../Sections/Contact/Contact";

export default function Platform() {
  return (
    <>
      <PlatformHero />

      <Offering />
      
      <br />
      <br />
      <br />
      
      <MediaQuery maxWidth={991}>
        <Featuresmobile copy={copyPlatform} />
      </MediaQuery>
      <MediaQuery minWidth={992}>
        <Features copy={copyPlatform} />
      </MediaQuery>

      <InsightsPlatform />

      {/* <Radiologyconsultations /> */}
      {/* <MediaQuery minWidth={992}>
        <PricingPlan />
      </MediaQuery>
      <MediaQuery maxWidth={991}>
        <PricingPlanmobile />
      </MediaQuery> */}

      {/* <Faq copy={copyfaq1} /> */}

      {/* <Contact /> */}
    </>
  );
}
