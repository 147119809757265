export const THEME = {
  PRIMARY: "black",
  SECONDARY: "#8362AA",
  LIGHTGREY: "#333333",
  PINK: "rgba(240, 101, 165, 0.88)",
  WHITE: "white",
  BLACK: "black",
  RED: "red",
  YELLOW: "#E2F27F",
};
export const LINK = {
  Login: "https://app.brell.health",
  code: "https://app.brell.health/code",
  clinics: "https://app.brell.health/clinics",
  Webflow: "https://consult.brell.health",
  Revo: "https://app.brell.health/revo",
  blog: "https://consult.brell.health/resources/understanding-the-benefits-of-a-radiology-second-opinion-for-mri-scans",
};