import "./App.css";
import Header from "./Components/Header/Header";

import { Routes, Route, useSearchParams } from "react-router-dom";
import Platform from "./Pages/Platform/Platform";
import Radiologists from "./Pages/Radiologists/Radiologists";
import Clinics from "./Pages/Clinics/Clinics";
import Patientspage from "./Pages/Patientspage/Patientspage";
// import Referringphysicians from "./Pages/Referring physicians/Referring physicians";
import Footer from "./Components/Footer/Footer";
import Records from "./Components/Records/Records";
// import PricingPlan from "./Sections/PricingPlan/PricingPlan";
import TermsCondions from "./Pages/Terms&Condions/Terms&Condions";
import Privacy from "./Pages/Privacy/Privacy";
import React from "react";
import { LINK } from "./const";
// import Blog from "./Pages/Blog/Blog";
import ScrollToTop from "./Components/scroll-to-top";

function App() {
  const [searchParams] = useSearchParams()
  const embed = searchParams.get('embed') === 'true'

  const RedirectPage = ({to}:any) => {
    React.useEffect(() => {
      window.location.replace(to);
    }, [to]);
    return null;
  };

  return (
    <>
    <ScrollToTop />
      {!embed && <Header />}
      <Routes>
        <Route path="/" element={<Platform />} />
        <Route path="/blog/" element={<RedirectPage to={LINK.blog}/>} />
        <Route path="/platform/" element={<Platform />} />
        <Route path="/code/" element={<RedirectPage to={LINK.code}/>} />
        <Route path="/code" element={<RedirectPage to={LINK.code}/>} />
        <Route path="/clinics/" element={<RedirectPage to={LINK.clinics}/>} />
        <Route path="/clinics" element={<RedirectPage to={LINK.clinics}/>} />
        <Route path="/login/" element={<RedirectPage to={LINK.Login}/>} />
        <Route path="/radiologists/" element={<Radiologists />} />
        <Route path="/specialists/" element={<Radiologists />} />
        <Route path="/care-centers/" element={<Clinics />} />
        <Route path="/imaging-centers/" element={<Clinics />} />
        <Route path="/clinics/" element={<Clinics />} />
        <Route path="/patients/" element={<Patientspage />} />
        {/* <Route path="/physicians/" element={<Referringphysicians />} /> */}
        {/* <Route path="/pricing/" element={<PricingPlan />} /> */}
        <Route path="/terms/" element={<TermsCondions />} />
        <Route path="/privacy/" element={<Privacy />} />
        <Route path="/records/" element={<Records />} />
        <Route path="/consult/" element={<RedirectPage to={LINK.Webflow}/>} />
        {/* <Route path="/blog/" element={<Blog />} /> */}
        <Route path="/revo/" element={<RedirectPage to={LINK.Revo}/>} />
      </Routes>

      {!embed && <Footer />}
    </>
  );
}

export default App;
