import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Text from "../Text/Text";
import "./Carousel.css";
import { Button } from "react-bootstrap";
import arrow from "./Img/Arrow 5.svg";
import { Link } from "react-router-dom";
interface slider {
  slideItem?: any;
  img?: any;
}
export default function Slider({ slideItem, img }: slider) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
  };
  return (
    <Carousel
      partialVisible={true}
      responsive={responsive}
      additionalTransfrom={0}
      arrows={false}
      autoPlaySpeed={30000000}
      centerMode={false}
      className=""
      containerClass="container"
      dotListClass="styledot"
      draggable
      focusOnSelect={false}
      infinite
      itemClass="item"
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      showDots={true}
      sliderClass="pt-5 pb-5"
      slidesToSlide={1}
      swipeable
    >
      {slideItem?.map(
        (
          value: { heading: any; description: any; img: any; link: any },
          index: any
        ) => {
          return (
            <div key={index}>
              <img src={value.img} style={{ maxHeight: "400px", }} alt="" />
              <div className="carouselPadding">
                <Text
                  className="pt-4 respicTitle"
                  regular
                  color="white"
                  text={value.heading}
                />
                <Text
                  className="pt-4 "
                  small
                  color="white"
                  text={value.description}
                />

                <div className="mt-5 mb-4">
                  {/* <Link to={"/blog/"}> */}
                    <Button
                      onClick={()=>window.location.href=value.link}
                      style={{
                        backgroundColor: "transparent",
                        borderStyle: "none",
                      }}
                    >
                      Read <img src={arrow} alt="" />
                    </Button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          );
        }
      )}
    </Carousel>
  );
}
