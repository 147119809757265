interface Itext {
  text?: any;
  bold?: boolean;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  regular?: boolean;
  small?: boolean;
  medium?: boolean;
  color?: any;
  extraBold?: boolean;
  className?: any;
  xs?: boolean;
  fontSize?: string;
  lineHeight?: string;
}
export default function Text({
  text,
  bold,
  h1,
  h2,
  h3,
  h4,
  regular,
  small,
  color,
  extraBold,
  className,
  medium,
  xs,
  fontSize,
  lineHeight
}: Itext) {
  return (
    <h1
      className={className}
      style={{
        fontFamily: medium
          ? "IBMPlexSans-Medium"
          : bold
          ? "IBMPlexSans-Bold"
          : "IBMPlexSans-Regular",
        fontSize: fontSize
          ? fontSize
          : h1
          ? "64px"
          : h2
          ? "3.5vw"
          : h3
          ? "2.7vw"
          : h4
          ? "2.2vw"
          : regular
          ? "1.7vw"
          : small
          ? "16px"
          : xs
          ? "13px"
          : "24px",
        lineHeight: lineHeight,
        color,
        // whiteSpace: "pre-line",                  
      }}
    >
      {text}
    </h1>
  );
}
