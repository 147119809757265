import { Col, Container, Row } from "react-bootstrap";
import Text from "../../Components/Text/Text";
import { THEME } from "../../const";
// import recordedConsult2 from "./Img/report-concepts.png";
// import reportConcepts from "./Img/recorded-consult-2.png";
import productPatientRoom from "./Img/product_patient_room.png";
import productSEM from "./Img/product_expert_opinion_management.png";
import MediaQuery from "react-responsive";
import data from "../../Data/Data";
// import { useMediaQuery } from "react-responsive";
import ReactPlayer from "react-player";

// const videoUlr = "https://youtu.be/O5HreUX2OKQ";
const videoUlr =
  "https://s3.ca-central-1.amazonaws.com/brell.health/videos/Brell+Health+-+Explainer.mp4";
// const videoSubUlr =
//   "https://s3.ca-central-1.amazonaws.com/brell.health/videos/Brell+Health+-+Explainer.srt";

export default function Offering() {
  // const isMobile = useMediaQuery({
  //   query: "(max-width: 900px)",
  // });

  return (
    <>
      <div className="text-center " style={{}}>
        <Container
          fluid
          style={{
            paddingLeft: "2rem",
            paddingRight: "2rem",
            paddingTop: "15vh",
            paddingBottom: "0vh",
            // backgroundImage: `url(${bgimg}) `,
            backgroundPosition: "TOP",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Row className="justify-content-center">
            <MediaQuery minWidth={701}>
              <Col lg={8} style={{ width: "80%", justifyContent: "start" }}>
                <div className="justify-content-start">
                  <Text
                    h2
                    bold
                    color={THEME.BLACK}
                    text={data.Platform["What is it"].title}
                  />
                </div>
                <br />
                <br />
                {/* <div className="pt-5" style={{ textAlign: "left" }}>
                  <Text
                    fontSize="20px"
                    lineHeight="1.9rem"
                    color={THEME.BLACK}
                    text={data.Platform["What is it"].sub}
                  />
                </div>
                <div className="pt-5" style={{ textAlign: "left" }}>
                  <Text
                    fontSize="20px"
                    lineHeight="1.9rem"
                    color={THEME.BLACK}
                    text={data.Platform["What is it"].sub2}
                  />
                </div> */}
                <Row>
                  <Col
                    style={{
                      textAlign: "left",
                      alignItems: "center",
                      display: "flex",
                      paddingRight: "4rem",
                    }}
                  >
                    <Text
                      fontSize="20px"
                      lineHeight="1.9rem"
                      color={THEME.BLACK}
                      text={data.Platform["What is it"].sub3}
                    />
                  </Col>
                  <Col>
                    <ReactPlayer
                      url={videoUlr}
                      controls
                      playbackRate={1.2}
                      // muted
                      // autoPlay
                      width="100%"
                      height="100%"
                      style={{
                        minHeight: "400px",
                        maxHeight: "500px",
                        borderRadius: "15px",
                        overflow: "hidden",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                      }}
                      config={{
                        file: {
                          attributes: {
                            controlsList: "nodownload",
                            // crossOrigin: 'Access-Control-Allow-Origin',
                          },
                          // tracks: [
                          //   {
                          //     kind: "subtitles",
                          //     src: videoSubUlr,
                          //     srcLang: "en",
                          //     label: "English",
                          //     default: true,
                          //   },
                          // ],
                        },
                      }}
                    />
                  </Col>
                </Row>
                <br />
                <br />
                <br />
                <Row className="pt-5 align-items-center">
                  <Col>
                    <img
                      src={productSEM}
                      style={{
                        // maxWidth: "100%",
                        // clipPath: "inset(25px 2px 2px 2px round 15px)",
                        borderRadius: "15px",
                        boxShadow: "25px",
                      }}
                      alt="article"
                    />
                  </Col>
                  <Col style={{ marginLeft: "5rem" }}>
                    <div style={{ textAlign: "left", alignItems: "center" }}>
                      <Text
                        medium
                        color={THEME.BLACK}
                        text={data.Platform.Offerings.title2}
                      />
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <Text
                        fontSize="20px"
                        lineHeight="1.9rem"
                        color={THEME.BLACK}
                        text={data.Platform.Offerings.text2}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="pt-5 align-items-center">
                  <Col style={{ marginRight: "5rem" }}>
                    <div style={{ textAlign: "left" }}>
                      <Text
                        medium
                        color={THEME.BLACK}
                        text={data.Platform.Offerings.title1}
                      />
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <Text
                        fontSize="20px"
                        lineHeight="1.9rem"
                        color={THEME.BLACK}
                        text={data.Platform.Offerings.text1}
                      />
                    </div>
                  </Col>
                  <Col>
                    <img
                      src={productPatientRoom}
                      style={{
                        // maxWidth: "100%",
                        // clipPath: "inset(25px 2px 2px 2px round 15px)",
                        borderRadius: "15px",
                        boxShadow: "25px",
                      }}
                      alt="article"
                    />
                  </Col>
                </Row>
              </Col>
            </MediaQuery>

            {/* mob view start */}
            <MediaQuery maxWidth={700}>
              <Col lg={8}>
                <div>
                  <Text
                    className=""
                    fontSize="28px"
                    bold
                    color={THEME.BLACK}
                    text={data.Platform["What is it"].title}
                  />
                </div>
                {/* <div className="pt-5" style={{ textAlign: "left" }}>
                  <Text
                    fontSize="18px"
                    lineHeight="1.7rem"
                    color={THEME.BLACK}
                    text={data.Platform["What is it"].sub}
                  />
                </div>
                <div className="pt-5" style={{ textAlign: "left" }}>
                  <Text
                    fontSize="18px"
                    lineHeight="1.7rem"
                    color={THEME.BLACK}
                    text={data.Platform["What is it"].sub2}
                  />
                </div> */}
                <div className="pt-5" style={{ textAlign: "left" }}>
                  <Text
                    fontSize="18px"
                    lineHeight="1.7rem"
                    color={THEME.BLACK}
                    text={data.Platform["What is it"].sub3}
                  />

                  <ReactPlayer
                    url={videoUlr}
                    controls
                    // muted
                    // autoPlay
                    width="100%"
                    playbackRate={1.2}
                    // height="100%"
                    style={{
                      // minHeight: "400px",
                      // maxHeight: "500px",
                      marginTop: "3rem",
                      borderRadius: "15px",
                      overflow: "hidden",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    }}
                    config={{
                      file: {
                        attributes: {
                          controlsList: "nodownload",
                          // crossOrigin: 'Access-Control-Allow-Origin',
                        },
                        // tracks: [
                        //   {
                        //     kind: "subtitles",
                        //     src: videoSubUlr,
                        //     srcLang: "en",
                        //     label: "English",
                        //     default: true,
                        //   },
                        // ],
                      },
                    }}
                  />
                </div>
                <Row className="pt-5 align-items-center">
                  
                  <div style={{ textAlign: "left", marginTop: "2rem" }}>
                    <Text
                      medium
                      color={THEME.BLACK}
                      text={data.Platform.Offerings.title2}
                    />
                  </div>
                  <img
                    src={productSEM}
                    style={{
                      maxWidth: "100%",
                      clipPath: "inset(25px 2px 2px 2px round 15px)",
                    }}
                    alt="article"
                  />
                  <div style={{ textAlign: "left" }}>
                    <Text
                      fontSize="18px"
                      lineHeight="1.7rem"
                      color={THEME.BLACK}
                      text={data.Platform.Offerings.text2}
                    />
                  </div>
                  <Col></Col>
                </Row>
                <Row className="pt-5 align-items-center">
                  
                  <div
                    style={{
                      textAlign: "left",
                      alignItems: "center",
                      marginTop: "2rem",
                    }}
                  >
                    <Text
                      medium
                      color={THEME.BLACK}
                      text={data.Platform.Offerings.title1}
                    />
                  </div>
                  <img
                    src={productPatientRoom}
                    style={{
                      maxWidth: "100%",
                      clipPath: "inset(2px round 15px)",
                    }}
                    alt="article"
                  />
                  <div style={{ textAlign: "left" }}>
                    <Text
                      fontSize="18px"
                      lineHeight="1.7rem"
                      color={THEME.BLACK}
                      text={data.Platform.Offerings.text1}
                    />
                  </div>
                </Row>
              </Col>
            </MediaQuery>
            {/* mob view end */}
          </Row>
        </Container>
      </div>
    </>
  );
}
