import Text from "../../Components/Text/Text";
import { Col, Container, Row } from "react-bootstrap";
import img from "./Img/Group-286.svg";
import pinkimg from "./Img/pinkgroup.svg";
import yellowimg from "./Img/yellowgroup.svg";
import one from "./Img/1.svg";
import two from "./Img/2.svg";
import three from "./Img/3.svg";
import pinkone from "./Img/pink1.svg";
import pinktwo from "./Img/pink2.svg";
import pinkthree from "./Img/pink3.svg";
import yellowone from "./Img/yellow1.svg";
import yellowtwo from "./Img/yellow2.svg";
import yellowthree from "./Img/yellow3.svg";
import yellowparticle from "./Img/yellowparticle.png";
import pinkparticle from "./Img/pinkparticle.png";
import { Link } from "react-router-dom";

interface howitworks {
  pink?: boolean;
  yellow?: boolean;
  stepOneText?: string;
  stepOneLink?: string;
  stepTwoText?: string;
  stepThreeText?: string;
  stepOnedescription?: string;
  stepTwodescription?: string;
  stepThreedescription?: string;
}
export default function Howitworks({
  pink,
  yellow,
  stepOneText,
  stepOneLink,
  stepThreeText,
  stepTwoText,
  stepOnedescription,
  stepTwodescription,
  stepThreedescription,
}: howitworks) {
  return (
    <>
      <div style={{ paddingTop: "100px" }} className="text-center  pb-5">
        <Text bold h2 text={<>How It Works</>} />
      </div>
      <Container style={{ paddingBottom: "10vw" }} className="  mb-5">
        <Row className="justify-content-center ">
          <Col lg={10}>
            <Row className="">
              <Col lg={6}>
                <div
                  className=""
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    marginTop: "50px",
                  }}
                >
                  <Row className="align-items-center">
                    <Col lg={3}>
                      <div className="text-center">
                        {pink ? (
                          <img src={pinkone} alt="" />
                        ) : yellow ? (
                          <img src={yellowone} alt="" />
                        ) : (
                          <img src={one} alt="" />
                        )}
                      </div>
                    </Col>
                    <Col lg={9}>
                      <Text h4 bold text={stepOneText} />
                      <Text small text={stepOnedescription} />
                      {stepOneLink && <Link to={stepOneLink}>
                        <Text small text="this link" />
                      </Link>}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6}></Col>
              <Col lg={6}></Col>
              <Col lg={6}>
                <div
                  className=""
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    marginTop: pink ? "16vw" : yellow ? "17vw" : "14vw",
                  }}
                >
                  <Row className="align-items-center">
                    <Col lg={3}>
                      <div className="text-center">
                        {pink ? (
                          <img src={pinktwo} alt="" />
                        ) : yellow ? (
                          <img src={yellowtwo} alt="" />
                        ) : (
                          <img src={two} alt="" />
                        )}
                      </div>
                    </Col>
                    <Col lg={9}>
                      <Text h4 bold text={stepTwoText} />
                      <Text small text={stepTwodescription} />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6}>
                <div
                  className=""
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    marginTop: "15vw",
                  }}
                >
                  <Row className="align-items-center">
                    <Col lg={3}>
                      <div className="text-center">
                        {pink ? (
                          <img src={pinkthree} alt="" />
                        ) : yellow ? (
                          <img src={yellowthree} alt="" />
                        ) : (
                          <img src={three} alt="" />
                        )}
                      </div>
                    </Col>
                    <Col lg={9}>
                      <Text h4 bold text={stepThreeText} />
                      <Text small text={stepThreedescription} />
                    </Col>
                  </Row>
                </div>
              </Col>
              {pink ? (
                <img
                  style={{
                    position: "absolute",
                    maxWidth: "60%",
                    left: 0,
                    right: 0,
                    margin: "auto",
                    padding: "0px",
                    zIndex: "-1",
                    // paddingTop: "100px",
                  }}
                  src={pinkimg}
                  alt=""
                />
              ) : yellow ? (
                <img
                  style={{
                    position: "absolute",
                    maxWidth: "70%",
                    left: 0,
                    right: 0,
                    margin: "auto",
                    padding: "0px",
                  }}
                  src={yellowimg}
                  alt=""
                />
              ) : (
                <img
                  style={{
                    position: "absolute",
                    maxWidth: "60%",
                    left: 0,
                    right: 0,
                    margin: "auto",
                    padding: "0px",
                    zIndex:-1,
                  }}
                  src={img}
                  alt=""
                />
              )}
              {pink ? (
                <img
                  style={{
                    position: "absolute",
                    maxWidth: "10%",
                    right: 0,
                    padding: "0px",
                  }}
                  src={pinkparticle}
                  alt=""
                />
              ) : (
                <img
                  style={{
                    position: "absolute",
                    maxWidth: "10%",
                    left: 0,
                    padding: "0px",
                  }}
                  src={yellowparticle}
                  alt=""
                />
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
