import { Col, Container, Row } from "react-bootstrap";
import Accordions from "../../Components/Accordions/Accordions";
import Text from "../../Components/Text/Text";
import yellowparticle from "./Img/webvect.svg";
import rightfaqvect from "./Img/Vector.svg";
import Leftfaqvect from "./Img/leftvector.svg";
import MediaQuery from "react-responsive";

import "./Faq.css";
import React from "react";
interface FaqProps {
  copy: any;
}

interface copy {
  title: any;
  description: string;
  link?: string;
}
export default function Faq({ copy }: FaqProps) {
  return (
    <Container>
      <div style={{ paddingTop: "100px" }} className="text-center  pb-5">
        <Text className="resFaqheading" bold h2 text={"FAQ"} />
      </div>
      <Row className="justify-content-center">
        <Col md={8}>
          {copy.map(({ title, description, link }: copy, index: number) => (
            <React.Fragment key={index}>
              <Accordions title={title} description={description} link={link}/>
            </React.Fragment>
          ))}
        </Col>
        <img className="resFaqyellowvect" src={yellowparticle} alt="" />
        <img className="resrightfaqvect" src={rightfaqvect} alt="" />
        <MediaQuery maxWidth={600}>
          <img className="resLeftfaqvect" src={Leftfaqvect} alt="" />
        </MediaQuery>
      </Row>
    </Container>
  );
}
