import MediaQuery from "react-responsive";
// import Faq from "../../Sections/Faq/Faq";
import Features from "../../Sections/Features/Features";
import Featuresmobile from "../../Sections/Features/Featuresmobile";
import Patients from "../../Sections/Patients/Patients";
import Patientsmobile from "../../Sections/Patients/Patientsmobile";
import PatientsHowitworks from "../../Sections/PatientsHowitworks/PatientsHowitworks";
import InsightsPatients from "../../Sections/InsightsPatients/InsightsPatients";
import { copyPatients } from "../../Sections/Features/copy";
import Faq from "../../Sections/Faq/Faq";
import { copyfaq2 } from "../../Sections/Faq/copy";
import PatientsHero from "../../Sections/PatientsHero/PatientsHero";

export default function  Patientspage() {
  return (
    <>
      <PatientsHero />

      {/* Patients  */}
      <MediaQuery minWidth={992}>
        <Patients />
      </MediaQuery>
      <MediaQuery maxWidth={991}>
        <Patientsmobile />
      </MediaQuery>

      <PatientsHowitworks />
      
      <MediaQuery maxWidth={989}>
        <Featuresmobile copy={copyPatients} />
      </MediaQuery>
      <MediaQuery minWidth={990}>
        <Features copy={copyPatients} />
      </MediaQuery>

      <InsightsPatients />

      <Faq copy={copyfaq2} />
      {/* <Footer /> */}
    </>
  );
}
