import { Col, Container, Row } from "react-bootstrap";
import Text from "../../Components/Text/Text";
import one from "./Img/1.svg";
import two from "./Img/2.svg";
import three from "./Img/3.svg";
import pinkone from "./Img/pink1.svg";
import pinktwo from "./Img/pink2.svg";
import pinkthree from "./Img/pink3.svg";
import pinkparticle from "./Img/pinkparticle.png";
import yellowone from "./Img/yellow1.svg";
import yellowtwo from "./Img/yellow2.svg";
import yellowthree from "./Img/yellow3.svg";
import yellowparticle from "./Img/yellowparticle.png";
// purple images
import Group from "./Img/Group 419.svg";
import Group420 from "./Img/Group 420.svg";
import Group421 from "./Img/Group 421.svg";
// pink images
import Group367 from "./Img/Group 367.svg";
import Group407 from "./Img/Group 407.svg";
import Group409 from "./Img/Group 409.svg";
// yellow images
import React from "react";
import { Link } from "react-router-dom";
import "./Howitworks.css";
import Group374 from "./Img/Group-374.svg";
import Group410 from "./Img/Group-410.svg";
import Group423 from "./Img/Group-423.svg";

interface howitworks {
  pink?: boolean;
  yellow?: boolean;
  stepOneText?: string;
  stepOneLink?: string;
  stepTwoText?: string;
  stepThreeText?: string;
  stepOnedescription?: string;
  stepTwodescription?: string;
  stepThreedescription?: string;
}
export default function Howitworksmobile({
  pink,
  yellow,
  stepOneText,
  stepOneLink,
  stepTwoText,
  stepThreeText,
  stepOnedescription,
  stepTwodescription,
  stepThreedescription,
}: howitworks) {
  const howitworks = [
    {
      icon: pink ? pinkone : yellow ? yellowone : one,
      title: stepOneText,
      description: stepOnedescription,
      link: stepOneLink,
    },
    {
      img: pink ? Group409 : yellow ? Group423 : Group,
      className: pink
        ? "pinkrightlign"
        : yellow
        ? "yellowrightlign"
        : "rightlign",
    },
    {
      icon: pink ? pinktwo : yellow ? yellowtwo : two,
      title: stepTwoText,
      description: stepTwodescription,
    },
    {
      img: pink ? Group407 : yellow ? Group410 : Group420,
    },
    {
      icon: pink ? pinkthree : yellow ? yellowthree : three,
      title: stepThreeText,
      description: stepThreedescription,
    },
    {
      img: pink ? Group367 : yellow ? Group374 : Group421,
    },
  ];
  return (
    <>
      <div style={{ paddingTop: "100px" }} className="text-center  pb-5">
        <Text bold fontSize="32px" text="How It Works" />
      </div>
      <Container>
        <Row className="justify-content-center">
          {howitworks.map(
            ({ icon, title, description, link, img, className }, index) => {
              return (
                <React.Fragment key={index}>
                  <Col lg={8} className="" style={{zIndex: link ? 1000:-1}}>
                    <Row className="align-items-center" style={{}}>
                      <Col xs={2}>
                        <img src={icon} alt="" />
                      </Col>
                      <Col xs={8} style={{ height: "90px" }}>
                        <Text bold fontSize="25px" text={title} />
                        <Text fontSize="16px" text={description} />
                        {link && (
                          <Link to={link}>
                            <Text small text="this link" />
                          </Link>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  {img && (
                    <>
                      <Col className="text-end p-0">
                        <img
                          src={img}
                          style={{ marginRight: "-16px", zIndex: "-1" }}
                          alt=""
                        />
                      </Col>
                      <Col xs={2} className="p-0">
                        <div className={className} />
                      </Col>
                    </>
                  )}
                </React.Fragment>
              );
            }
          )}

          {yellow ? (
            <img src={yellowparticle} alt="" className="resyellowvectmob" style={{zIndex:-1}}/>
          ) : pink ? (
            <img src={pinkparticle} alt="" className="respinkvectmob" style={{zIndex:-1}} />
          ) : (
            <img src={yellowparticle} alt="" className="resyellowvectmob" style={{zIndex:-1}} />
          )}
        </Row>
      </Container>
    </>
  );
}
