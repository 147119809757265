import { Col, Container, Row } from "react-bootstrap";
import Text from "../../Components/Text/Text";
import bgimg from "./Img/bgimg.png";
import pinkicon from "./Img/pinkicon.svg";
import yellowicon from "./Img/yellowicon.svg";
import data from "../../Data/Data";
import purpleicon from "./Img/purpleicon.svg";
import quo from "./Img/quo.svg";
import React from "react";

export default function Radiologychangingmobile() {
  const list = [
    {
      icon: pinkicon,
      title: data.Radiologist.Benefits.items.item1title,
      description: data.Radiologist.Benefits.items.item1sub,
    },
    {
      icon: purpleicon,
      title: data.Radiologist.Benefits.items.item2title,
      description: data.Radiologist.Benefits.items.item2sub,
    },
    {
      icon: yellowicon,
      title: data.Radiologist.Benefits.items.item3title,
      description: data.Radiologist.Benefits.items.item3sub,
    },
  ];
  return (
    <>
      <div
        className="p-3 mt-5 radiobgimg"
        style={{
          paddingTop: "45vh",
          paddingBottom: "35vh",
          backgroundImage: `url(${bgimg}) `,
          backgroundPosition: "TOP",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderBottomLeftRadius: "35vh",
          overflowX: "hidden",
          opacity: "0.6px",
        }}
      >
        <Text
          className="text-center pt-5 pb-5"
          fontSize="34px"
          bold
          text={data.Radiologist.Benefits.title}
        />
        <div style={{ paddingBottom: "100px" }}>
          <div style={{ marginBottom: "-40px", paddingLeft: "20px" }}>
            <img src={quo} style={{ maxWidth: "28%" }} alt="" />
          </div>
          <Text
            className="text-center"
            fontSize="24px"
            bold
            text={data.Radiologist.Benefits.sub}
          />
        </div>
      </div>

      <Container>
        <Row className="">
          <Col
            lg={8}
            style={{
              marginTop: "-150px",
            }}
          >
            {list.map(({ title, icon, description }, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className="p-3 shadow"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                      marginTop: "50px",
                    }}
                  >
                    <Row className="align-items-center">
                      <Col lg={3}>
                        <div className="">
                          <img src={icon} alt="" />
                        </div>
                      </Col>
                      <Col lg={9}>
                        <Text
                          className="pt-2"
                          fontSize="25px"
                          bold
                          text={title}
                        />
                        <Text small text={description} />
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              );
            })}
          </Col>
        </Row>
      </Container>
    </>
  );
}
