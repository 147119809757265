import { Col, Container, Row } from "react-bootstrap";
import Text from "../../Components/Text/Text";

import "./Features.css";
import Accordions from "../../Components/Accordions/Accordions";
import React from "react";

interface FeaturesProps {
  copy: any;
}

interface copy {
  icon: any;
  title: string;
  description: string;
  bigBox?: boolean;
}

export default function Featuresmobile({ copy }: FeaturesProps) {
  return (
    <>
      <div style={{ paddingTop: "60px" }} className="text-center   ">
        <Text bold className="respFeatureheading" text={"Features"} />
      </div>
      <Container className=" mt-5 pb-5 mb-5">
        <Row>
          {copy.map(({ icon, title, description }: copy, index: number) => {
            return (
              <React.Fragment key={index}>
                <Col lg={12} className="mt-4">
                  <Accordions
                    icon={icon}
                    title={title}
                    description={description}
                  />
                </Col>
              </React.Fragment>
            );
          })}
        </Row>
      </Container>
    </>
  );
}
