import { Accordion, Card } from "react-bootstrap";
import ContextAwareToggle from "../ContextAwareToggle/ContextAwareToggle";
import Text from "../../Components/Text/Text";
import { Link } from "react-router-dom";

import "./Accordions.css";
interface accordion {
  title?: any;
  description?: any;
  icon?: any;
  link?: string;
}
export default function Accordions({
  title,
  description,
  icon,
  link,
}: accordion) {
  return (
    <Accordion defaultActiveKey="1" className="mt-3">
      <div style={{ backgroundColor: "white" }} className="shadow p-3">
        <Card.Header style={{ backgroundColor: "white", border: "none" }}>
          <ContextAwareToggle
            icon={icon}
            title={title}
            eventKey="0"
          ></ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body style={{ paddingLeft: "80px" }}>
            <span>
              {description}
              {link && (
                <Link to={link}>
                  this link.
                </Link>
              )}
            </span>
          </Card.Body>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
}
